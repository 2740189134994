import * as classNames from 'classnames';
import * as React from 'react';
import './TabItem.scss';

type TabItemProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  active?: boolean;
  testId?: string;
};

export const TabItem = ({ active, children, className, onClick, testId, ...rest }: TabItemProps) => {
  const tabClass = classNames(
    'TabItem',
    {
      'TabItem--active': active,
    },
    className
  );

  return (
    <>
      <button
        className={tabClass}
        data-testid={testId}
        onClick={(e) => {
          const target = e.target as HTMLButtonElement;

          if (!target) {
            return;
          }

          target.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });

          if (onClick) {
            onClick(e);
          }
        }}
        {...rest}
      >
        {children}
      </button>
    </>
  );
};
