import * as React from 'react';
import { Button, Dialog, DialogProps } from '~/src/ui';
import { UserModel } from '../../api';

type ResendVerificationProps = Omit<DialogProps, 'children'> & {
  email: string;
};

export const ResendVerification = ({ email, onClose, ...rest }: ResendVerificationProps) => {
  const [error, setError] = React.useState('');

  function clickResendVerifyEmail() {
    setError('');

    UserModel.listAction('resend_verify_email', 'post', {
      email: email,
    })
      .then((res) => {
        if (onClose) {
          onClose();
        }
      })
      .catch((err) => {
        if (!err.response.data) {
          return;
        }

        const { email, already_verified, non_field_errors } = err.response.data;

        if (email) {
          setError(email);
        } else if (already_verified) {
          setError(already_verified);
        } else if (non_field_errors) {
          setError(non_field_errors[0]);
        } else {
          setError('An error occurred. Please try again.');
        }
      });
  }

  return (
    <Dialog onClose={onClose} {...rest}>
      <h4>Email Verification Required</h4>
      <p>
        Please check <strong>{email}</strong> for a verification email from us.
      </p>
      <Button color="primary" onClick={clickResendVerifyEmail} testId="resend-button" variant="raised">
        Resend Verification Email
      </Button>
      {error && <p>{error}</p>}
    </Dialog>
  );
};
