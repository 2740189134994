import { EnvelopeIcon } from '@heroicons/react/24/outline';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import {
  GooglePlay,
  LogoFooter,
  LogoHeader,
  Phones,
  Slide1,
  Slide2,
  Slide3,
  Slide4,
  Slide5,
  Slide6,
  Slide7,
  Slide8,
  iOSAppStore,
} from '~/src/assets/img';
import { Badge, Button, Container, Input, Textarea, useToast } from '~/src/ui';
import { ContactModel, SubscriptionModel } from '../../api';
import { Carousel, PrivacyPolicyModal, WaveSvg, subtitleComponents } from '../../components';
import './LandingPage.scss';

const animationDuration = 4000;

export type SubscribeFormData = {
  email: string;
};

export type ContactFormData = {
  name: string;
  email: string;
  message: string;
};

export const LandingPage = () => {
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = React.useState(false);
  const [currentTextIndex, setCurrentTextIndex] = React.useState(0);

  const subscriptionRef = React.useRef<HTMLDivElement>(null);

  const subscribeForm = useForm<SubscribeFormData>();
  const contactForm = useForm<ContactFormData>();
  const { error, success } = useToast();

  function submitSubscribe(data: SubscribeFormData) {
    SubscriptionModel.create(data)
      .then(() => success('Thank you for subscribing!\nWe will keep you updated.', <EnvelopeIcon />))
      .catch(() => error('An error occurred while subscribing. Please try again later.'));
  }

  function submitContact(data: ContactFormData) {
    ContactModel.create(data)
      .then(() => {
        success("Thank you for contacting us!\nWe'll get back to you shortly.", <EnvelopeIcon />);
        contactForm.reset();
      })
      .catch((err) => {
        Object.keys(err.response?.data).forEach((key) => {
          const [message] = err.response.data[key];
          error(message);
        });
      });
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prev) => (prev + 1) % subtitleComponents.length);
    }, animationDuration);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="LandingPage">
        <div className="LandingPage__main">
          <Container>
            <div className="LandingPage__main__content">
              <img className="LandingPage__main__logo" src={LogoHeader} alt="Sqwabl Logo" />
              <h2 className="LandingPage__main__title">Make group decisions fun and easy with social voting.</h2>
              <div className="LandingPage__main__subtitle">{subtitleComponents[currentTextIndex]}</div>
              <Button
                color="white"
                className="LandingPage__main__button"
                rounded
                onClick={() => {
                  subscriptionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }}
                size="lg"
                variant="outlined"
              >
                Get Updated
              </Button>
              <Carousel
                className="LandingPage__main__carousel"
                duration={animationDuration}
                images={[
                  <img src={Slide1} alt="" />,
                  <img src={Slide2} alt="" />,
                  <img src={Slide3} alt="" />,
                  <img src={Slide4} alt="" />,
                  <img src={Slide5} alt="" />,
                  <img src={Slide6} alt="" />,
                  <img src={Slide7} alt="" />,
                  <img src={Slide8} alt="" />,
                ]}
              />
            </div>
          </Container>
        </div>
        <WaveSvg flipped />
        <div className="LandingPage__subscribe" ref={subscriptionRef}>
          <Container>
            <div className="LandingPage__subscribe__content">
              <div className="LandingPage__subscribe__text">
                <h3>Be the first to know when we launch</h3>
                <p>Get the most recent information straight to your inbox.</p>
              </div>
              <form className="LandingPage__subscribe__form" onSubmit={subscribeForm.handleSubmit(submitSubscribe)}>
                <div className="LandingPage__subscribe__form__inline">
                  <Input
                    fluid
                    placeholder="name@example.com"
                    type="email"
                    {...subscribeForm.register('email', { required: true })}
                  />
                  <Button
                    color="fandango"
                    className="LandingPage__subscribe__form__button"
                    disabled={!subscribeForm.formState.isValid}
                    type="submit"
                    variant="raised"
                  >
                    Submit
                  </Button>
                </div>
                <div className="LandingPage__subscribe__form__privacy">
                  We'll never share your contact details, see our{' '}
                  <a onClick={() => setPrivacyPolicyOpen(true)}>Privacy Policy</a>
                </div>
              </form>
            </div>
          </Container>
        </div>
        <WaveSvg />
        <div className="LandingPage__promo">
          <Container>
            <div className="LandingPage__promo__content">
              <Badge color="endeavour">Coming soon...</Badge>
              <h2 className="LandingPage__promo__title">We've got you covered.</h2>
              <p className="LandingPage__promo__subtitle">
                Epic game nights. Just the right restaurant. A movie to make everyone smile.
              </p>
              <div className="LandingPage__promo__buttons">
                <img src={GooglePlay} alt="Google Play Store" style={{ cursor: 'pointer' }} onClick={() => {}} />
                <img src={iOSAppStore} alt="iOS App Store" style={{ cursor: 'pointer' }} onClick={() => {}} />
              </div>
              <img className="LandingPage__promo__phones" src={Phones} alt="Phones" />
            </div>
          </Container>
        </div>
        <WaveSvg flipped />
        <div className="LandingPage__contact">
          <Container>
            <div className="LandingPage__contact__content">
              <Badge color="endeavour">Contact</Badge>
              <h2 className="LandingPage__contact__title">Connect with Us</h2>
              <p className="LandingPage__contact__subtitle">
                Reach out, share your thoughts, or ask any questions. Your feedback matters, and we'd love to hear from
                you. Fill out the form below, and let's start the conversation.
              </p>
              <form className="LandingPage__contact__form" onSubmit={contactForm.handleSubmit(submitContact)}>
                <div>
                  <label htmlFor="name">Name</label>
                  <Input
                    fluid
                    id="name"
                    placeholder="Name"
                    type="text"
                    {...contactForm.register('name', { required: true })}
                  />
                </div>
                <div>
                  <label htmlFor="email">Email</label>
                  <Input
                    fluid
                    id="email"
                    placeholder="email@example.com"
                    type="email"
                    {...contactForm.register('email', { required: true })}
                  />
                </div>
                <div>
                  <label htmlFor="message">Message</label>
                  <Textarea
                    fluid
                    id="message"
                    placeholder="Your message"
                    {...contactForm.register('message', { required: true })}
                  />
                </div>
                <Button
                  color="fandango"
                  disabled={!contactForm.formState.isValid}
                  size="lg"
                  type="submit"
                  variant="raised"
                >
                  Send
                </Button>
              </form>
            </div>
          </Container>
        </div>
        <div className="LandingPage__footer">
          <Container>
            <div className="LandingPage__footer__content">
              <p>© 2023 Sqwabl. All rights reserved.</p>
              <img src={LogoFooter} alt="Sqwabl Logo" />
            </div>
          </Container>
        </div>
      </div>
      <PrivacyPolicyModal isOpen={privacyPolicyOpen} onClose={() => setPrivacyPolicyOpen(false)} />
    </>
  );
};
