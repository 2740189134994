import * as React from 'react';
import { Dialog } from '~/src/ui';
import './PrivacyPolicyModal.scss';

type PrivacyPolicyModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const PrivacyPolicyModal = (props: PrivacyPolicyModalProps) => {
  return (
    <Dialog isOpen={props.isOpen} onClose={props.onClose} className="PrivacyPolicyModal">
      <div className="PrivacyPolicyModal__header">
        <p className="PrivacyPolicyModal__title">Privacy Policy</p>
        <p className="PrivacyPolicyModal__classic">Last updated: January 23, 2024</p>
      </div>
      <div className="PrivacyPolicyModal__innerScroll">
        <div className="PrivacyPolicyModal__section">
          <p className="PrivacyPolicyModal__classic">
            Sqwabl Inc. (<span className="PrivacyPolicyModal__emphasized">"Company"</span>,
            <span className="PrivacyPolicyModal__emphasized">"we"</span>,
            <span className="PrivacyPolicyModal__emphasized">"us"</span>, or{' '}
            <span className="PrivacyPolicyModal__emphasized">"our"</span>) is committed to protecting the privacy of
            Personal Information we process as a data controller, covered business, or data processor (as applicable) in
            the provision of our services, products, or solutions available on our Online Platforms (collectively, our
            <span className="PrivacyPolicyModal__emphasized">"Services"</span>). This Privacy Policy & Notice (
            <span className="PrivacyPolicyModal__emphasized">"Privacy Policy"</span>) applies to your interaction with
            the Company and explains what Personal Information we process, the purposes of our processing activities,
            the parties with whom we may share Personal Information, your consumer rights with the Company, and certain
            security matters concerning our processing activities.
          </p>
          <p className="PrivacyPolicyModal__classic">
            PLEASE READ THIS PRIVACY POLICY CAREFULLY BEFORE USING OUR ONLINE PLATFORMS OR ACCESSING OUR SERVICES.
          </p>
        </div>
        <div className="PrivacyPolicyModal__section">
          <p className="PrivacyPolicyModal__subtitle">OUR PROCESSING RELATIONSHIP WITH YOU.</p>
          <p className="PrivacyPolicyModal__classic">
            When you visit our Online Platforms to investigate or use our Services, we are generally the controller of
            Personal Information we collect during that interaction, and we will use and process it in accordance with
            this Privacy Policy. However, if you or your organization conduct business transactions directly with us to
            access our Services through written agreements with us, we may be acting as a service provider or processor
            on your behalf; and in those scenarios, we will only handle your information on written request, according
            to this Privacy Policy and any stand-alone agreement with you, to the extent applicable.
          </p>
        </div>
        <div className="PrivacyPolicyModal__section">
          <p className="PrivacyPolicyModal__subtitle">1. INFORMATION WE COLLECT AND HOW WE COLLECT THE INFORMATION.</p>
          <p className="PrivacyPolicyModal__classic">
            The information we collect depends on how you interact with us. We may collect information about you: (1)
            when you visit or register to use our website and online platforms, including mobile applications
            (collectively, our <span className="PrivacyPolicyModal__emphasized">"Online Platforms"</span>), and actively
            input information; or (2) through automated Tracking Technologies (defined below) from your device when you
            interact with us through the Online Platforms.{' '}
          </p>
          <div className="PrivacyPolicyModal__orderedList">
            <p className="PrivacyPolicyModal__orderedList__number PrivacyPolicyModal__classic">1.1</p>
            <div className="PrivacyPolicyModal__orderedList__content">
              <p className="PrivacyPolicyModal__classic">
                <span className="PrivacyPolicyModal__bold">
                  Information collected when accessing the Online Platforms.
                </span>{' '}
                Given the nature and design purposes of our Online Platforms, we may collect the following
                <span className="PrivacyPolicyModal__emphasized">"Personal Information"</span> about you when you use
                our Online Platforms:
              </p>
              <ul>
                <li className="PrivacyPolicyModal__classic">
                  <span className="PrivacyPolicyModal__italic">Identifiers</span>: Your name, login credentials if you
                  register an account on the Online Platforms (such as our mobile app), and contact and age verification
                  information (including phone number and e-mail address).
                </li>
                <li className="PrivacyPolicyModal__classic">
                  <span className="PrivacyPolicyModal__italic">Characteristics and Descriptions</span>: Content such as
                  messages, comments, photos, videos, social media type posts, survey or list responses, and other
                  information or feedback submitted by you, at your election, through the Online Platforms (if any).
                </li>
                <li className="PrivacyPolicyModal__classic">
                  <span className="PrivacyPolicyModal__italic">Internet or other Network Activity</span>: Information
                  about the device you use to access the Services, including operating system, Internet Protocol (IP)
                  address, web browser type, traffic logs, and mobile network information.
                </li>
                <li className="PrivacyPolicyModal__classic">
                  <span className="PrivacyPolicyModal__italic">Device Data</span>: Information about the language,
                  general location (such as time zone), and configuration of the device you use to access the Online
                  Platforms.
                </li>
              </ul>
            </div>
          </div>
          <div className="PrivacyPolicyModal__orderedList">
            <p className="PrivacyPolicyModal__orderedList__number PrivacyPolicyModal__classic">1.2</p>
            <div className="PrivacyPolicyModal__orderedList__content">
              <p className="PrivacyPolicyModal__classic">
                <span className="PrivacyPolicyModal__bold">Manner of Collection.</span> As noted above, we collect this
                information directly from you, or automatically from you and your device using Tracking Technologies,
                when you use the Online Platforms. We may also periodically obtain both Personal Information and
                non-personal information about you from affiliates, business partners, add services, and other third
                parties when those parties lawfully share the information with us.
              </p>
            </div>
          </div>
          <p className="PrivacyPolicyModal__classic">
            YOU ARE NOT REQUIRED TO PROVIDE PERSONAL INFORMATION TO THE COMPANY (FOR EXAMPLE, THROUGH DISABLING TRACKING
            TECHNOLOGIES). However, if you do not provide us with Personal Information or if you modify your device
            settings to limit Tracking Technologies (which you have full discretion to do), you may not be able to use
            certain features of the Online Platforms or engage in online business transactions with us to use our
            Services.
          </p>
          <p className="PrivacyPolicyModal__classic">
            WE GENERALLY DO NOT COLLECT INFORMATION THAT IS CONSIDERED SENSITIVE UNDER DATA PROTECTION LAWS, unless such
            information is submitted by you through the Online Platforms. In such a scenario, we will only hold and
            store the sensitive data on the Online Platforms for the limited purposes described in this Privacy Policy
            (subject, at all times, to your opt-out rights under applicable laws).
          </p>
        </div>
        <div className="PrivacyPolicyModal__section">
          <p className="PrivacyPolicyModal__subtitle">2. PURPOSES FOR WHICH WE MAY USE YOUR INFORMATION.</p>
          <p className="PrivacyPolicyModal__classic">
            We collect, use, and process your Personal Information and non-personal information to pursue our legitimate
            interests in providing an exceptional Online Platform with engaging features, perform contractual
            obligations with you, and market and deliver our Services, including to:
          </p>
          <ul>
            <li className="PrivacyPolicyModal__classic PrivacyPolicyModal__italic">
              Operate, maintain, and provide features of the Online Platforms.
            </li>
            <li className="PrivacyPolicyModal__classic PrivacyPolicyModal__italic">
              Perform our contractual obligations with our clients and customers, including to deliver our Services or
              other business solutions.
            </li>
            <li className="PrivacyPolicyModal__classic PrivacyPolicyModal__italic">
              Contact users of the Online Platforms and customers to provide customer support after such users and
              customers voluntarily contact us.
            </li>
            <li className="PrivacyPolicyModal__classic PrivacyPolicyModal__italic">
              Conduct surveys for our own internal, analytical, and marketing purposes.
            </li>
            <li className="PrivacyPolicyModal__classic PrivacyPolicyModal__italic">
              Comply with legal obligations and prevent illegal activity, fraud, or abuse.
            </li>
            <li className="PrivacyPolicyModal__classic PrivacyPolicyModal__italic">
              Enforce or defend the intellectual property and other rights underlying our Services if we detect misuse
              of those rights.
            </li>
            <li className="PrivacyPolicyModal__classic PrivacyPolicyModal__italic">
              Provide you with targeted advertising or other marketing notices concerning offerings of our Services or
              related products.
            </li>
            <li className="PrivacyPolicyModal__classic PrivacyPolicyModal__italic">
              Use the information as otherwise described to you in any stand-alone agreement or EULA.
            </li>
          </ul>
        </div>
        <div className="PrivacyPolicyModal__section">
          <p className="PrivacyPolicyModal__subtitle">3. HOW AND WITH WHOM WE SHARE YOUR INFORMATION.</p>
          <p className="PrivacyPolicyModal__classic">
            We may use, process, or sell aggregate information that does not identify you, such as survey responses,
            collections and lists, and other statistical information, for any purpose permissible under applicable data
            and consumer privacy laws. We may share your Personal Information for commercial purposes with others, such
            as our subsidiaries or affiliates, contractors, service providers (including third party marketing
            partners), developers, and other third parties we use to support our Services so long as those parties are
            bound by contractual or fiduciary obligations to keep your Personal Information secure and to use it only
            for the purposes for which we disclose it to them (e.g., in support of our Services and Online Platforms).
          </p>
          <p className="PrivacyPolicyModal__classic">
            We may also share Personal Information if we believe in good faith that doing so is required by law or is
            otherwise required to exercise or defend legal rights, take precautions against liability, protect the
            rights of any individual, protect the integrity of the Online Platforms, protect the Company or others from
            fraudulent or unlawful activity, or defend the Company against third party claims.
          </p>
          <p className="PrivacyPolicyModal__classic">
            In general, we do not submit your Personal information to any automated decision-making technologies or
            tools (each, an <span className="PrivacyPolicyModal__emphasized">"AI"</span>) unless doing so is reasonably
            related to one of the purposes described in Section 2 above. That said, if you wish to opt-out of any AI use
            of your Personal Information (assuming we have implemented any such AI) or request further information
            concerning our application of AI, please contact us at the information below.
          </p>
        </div>
        <div className="PrivacyPolicyModal__section">
          <p className="PrivacyPolicyModal__subtitle">4. STORAGE OF PERSONAL INFORMATION.</p>
          <p className="PrivacyPolicyModal__classic">
            To support our Services and business, the Company may collect, store, and process your Personal Information
            on our premises, data platforms, and private cloud storage solutions in the United States. The transfer or
            disclosure of your Personal Information to our U.S. systems, and storage in those systems, is necessary to
            enable the Company and our service providers to give essential support to the Online Platforms.
          </p>
        </div>
        <div className="PrivacyPolicyModal__section">
          <p className="PrivacyPolicyModal__subtitle">5. CONSUMER RIGHTS CONCERNING PERSONAL INFORMATION.</p>
          <div className="PrivacyPolicyModal__orderedList">
            <p className="PrivacyPolicyModal__orderedList__number PrivacyPolicyModal__classic">5.1</p>
            <div className="PrivacyPolicyModal__orderedList__content">
              <p className="PrivacyPolicyModal__classic">
                <span className="PrivacyPolicyModal__bold">U.S. Consumer Rights.</span> Pursuant to applicable U.S. data
                protection and consumer privacy laws, natural persons and data subjects whose Personal Information we
                process (including you) may contact us to request the exercise of certain rights concerning their
                Personal Information, including the right to: (i) request that we disclose the categories and specific
                pieces of Personal Information we have collected and disclosed about those persons; (ii) confirm and
                know whether we have processed or sold Personal Information; (iii) request information about the
                Company's disclosure of Personal Information to our affiliates and service providers (including for
                their marketing purposes); (iv) opt-out of the Company sharing Personal Information with third parties
                and our service providers; (v) request that we provide a copy of their Personal Information to them
                (data portability); (vi) request that we update Personal Information or correct any inaccuracies; (vii)
                opt-out of any direct or indirect profiling or targeted advertising we may engage in based on use of the
                Online Platforms; or (viii) request that we otherwise delete applicable Personal Information. Consumers
                may also have the right to lodge a complaint with a supervisory authority if they believe we have
                breached the terms of this Privacy Policy with respect to their Personal Information. Depending on where
                those persons are located, some of these rights may not apply or may be subject to limitations. For
                example, we may limit the number of requests or charge reasonable fees as legally permitted. CONSUMERS
                AND DATA SUBJECTS WILL NOT FACE DISCRIMINATION FOR EXERCISING THEIR RIGHTS WITH RESPECT TO PERSONAL
                INFORMATION.
              </p>
            </div>
          </div>
          <div className="PrivacyPolicyModal__orderedList">
            <p className="PrivacyPolicyModal__orderedList__number PrivacyPolicyModal__classic">5.2</p>
            <div className="PrivacyPolicyModal__orderedList__content">
              <p className="PrivacyPolicyModal__classic">
                <span className="PrivacyPolicyModal__bold">International Privacy Rights (e.g., GDPR).</span> In
                addition, pursuant to applicable data protection laws in non-U.S. jurisdictions (such as the European
                Union and the United Kingdom), you may contact us to request the exercise of the following rights with
                respect to your Personal Information:
              </p>
              <ul>
                <li className="PrivacyPolicyModal__classic">Erase or delete your Personal Information.</li>
                <li className="PrivacyPolicyModal__classic">Access your Personal Information.</li>
                <li className="PrivacyPolicyModal__classic">Restrict the processing of your Personal Information.</li>
                <li className="PrivacyPolicyModal__classic">Correct or update your Personal Information.</li>
                <li className="PrivacyPolicyModal__classic">
                  Receive a copy of your Personal Information (data portability).
                </li>
                <li className="PrivacyPolicyModal__classic">Object to the processing of your Personal Information.</li>
                <li className="PrivacyPolicyModal__classic">
                  Withdraw consent to the processing of your Personal Information, if we relied on your consent to
                  collect and process any such information.
                </li>
              </ul>
            </div>
          </div>
          <div className="PrivacyPolicyModal__orderedList">
            <p className="PrivacyPolicyModal__orderedList__number PrivacyPolicyModal__classic">5.3</p>
            <div className="PrivacyPolicyModal__orderedList__content">
              <p className="PrivacyPolicyModal__classic">
                <span className="PrivacyPolicyModal__bold">Exercising your rights.</span> A consumer or their authorized
                representative may contact us (at the contact information below) to request the exercise of these rights
                set out in the Sections above.
              </p>
            </div>
          </div>
          <div className="PrivacyPolicyModal__orderedList">
            <p className="PrivacyPolicyModal__orderedList__number PrivacyPolicyModal__classic">5.4</p>
            <div className="PrivacyPolicyModal__orderedList__content">
              <p className="PrivacyPolicyModal__classic">
                <span className="PrivacyPolicyModal__bold">Opt-out instructions.</span> If any consumer or natural
                person wishes to opt-out of certain features of the Online Platforms (including targeted advertising or
                surveys), please contact us at the information set out below and we will provide instructions.
              </p>
            </div>
          </div>
        </div>
        <div className="PrivacyPolicyModal__section">
          <p className="PrivacyPolicyModal__subtitle">6. DATA RETENTION PERIOD.</p>
          <p className="PrivacyPolicyModal__classic">
            We retain Personal Information for a reasonable period to fulfill the purposes for which we collected it (as
            outlined in this Privacy Policy), including to defend our rights under our Online Platforms and agreements
            (if any) with you. If we no longer reasonably need Personal Information for such purposes, we will, at our
            election and in accordance with applicable data protection laws, delete it from our records, anonymize the
            Personal Information, or archive the Personal Information in a manner consistent with industry standards for
            protection and retention of Personal Information, unless applicable law or legal considerations necessitate
            a different retention period (as the case may be).
          </p>
        </div>
        <div className="PrivacyPolicyModal__section">
          <p className="PrivacyPolicyModal__subtitle">7. SECURITY MEASURES.</p>
          <p className="PrivacyPolicyModal__classic">
            We use reasonable technical and organizational measures to protect Personal Information against loss,
            misuse, and unauthorized access, use, or alteration (including, in our discretion, through implementation of
            security protocols, traffic logs, and other programs to track visitors on our Online Platforms). However, we
            cannot guarantee the complete security of these measures, so please exercise caution when submitting
            Personal Information online or browsing any online website.
          </p>
        </div>
        <div className="PrivacyPolicyModal__section">
          <p className="PrivacyPolicyModal__subtitle">8. COOKIES AND TRACKING TECHNOLOGY.</p>
          <p className="PrivacyPolicyModal__classic">
            In addition to our direct collection efforts through the Online Platforms, the Company may use, in our
            reasonable discretion, various tracking technologies, collection systems, cookies, or Google Analytics
            (collectively, <span className="PrivacyPolicyModal__emphasized">"Tracking Technologies"</span>) to collect
            Personal Information and non-personal information from your device based on your activity on our Online
            Platforms, doing so according to this Privacy Policy.
          </p>
          <p className="PrivacyPolicyModal__classic">
            As you may already know, Tracking Technologies retain information about your activity on our Online
            Platforms and other online locations to disclose that information to us or the owner of the applicable
            platform or webpage. When you access our Online Platforms, we may use Tracking Technologies to follow your
            usage of our mobile app or website and the information you review. The information we obtain in this manner
            enables the Company to customize the Online Platforms and to measure the overall effectiveness of our online
            presence. We may also allow third parties, advertising companies, and ad networks to display advertisements
            on our Online Platforms, some of which may include links to those third-parties' own applications or sites.
            These companies may also use Tracking Technologies to collect information about users viewing or interacting
            with their advertisements.
          </p>
          <p className="PrivacyPolicyModal__classic">
            You may choose to opt out of certain Tracking Technologies by modifying your browser and device settings. If
            you do so, however, some of the information or content on our Online Platforms may be inaccessible. WE
            RECOMMEND YOU REVIEW YOUR DEVICE SETTINGS TO CONFIRM IF TRACKING TECHNOLOGIES, INCLUDING GEOLOCATION
            TRACKING, HAVE BEEN PROPERLY DISABLED OR CONFIGURED TO YOUR PREFERENCE.
          </p>
        </div>
        <div className="PrivacyPolicyModal__section">
          <p className="PrivacyPolicyModal__subtitle">9. CHILDREN'S PRIVACY.</p>
          <p className="PrivacyPolicyModal__classic">
            Our Online Platforms are{' '}
            <span className="PrivacyPolicyModal__bold" style={{ textDecoration: 'underline' }}>
              not
            </span>{' '}
            intended for persons under 16 years of age. If you are under the age of 16, please do not provide any
            Personal Information to the Company through the Online Platforms or register an account on the Online
            Platforms. We do not knowingly collect Personal Information from anyone under the age of 16, and if you
            believe we might have any information from or about a person under the age of 16, please contact us
            immediately at the information set out below.
          </p>
        </div>
        <div className="PrivacyPolicyModal__section">
          <p className="PrivacyPolicyModal__subtitle">10. THIRD-PARTY LINKS.</p>
          <p className="PrivacyPolicyModal__classic">
            Occasionally, third-party links, products, or services might appear to you as you use our Online Platforms.
            These third-party sites have separate and independent privacy policies, notices of collection, and terms of
            use. We do not assume responsibility or liability for the content and activities of these linked sites, and
            we encourage you to directly review the policies and privacy practices on those sites.
          </p>
        </div>
        <div className="PrivacyPolicyModal__section">
          <p className="PrivacyPolicyModal__subtitle">11. UPDATES TO OUR PRIVACY POLICY.</p>
          <p className="PrivacyPolicyModal__classic">
            We may update or change this Privacy Policy from time to time based on changes in law or internal review of
            our policies and procedures. When we perform an update, we will amend the "last updated" date above and
            notify you if such changes materially affect your rights or our obligations under this Privacy Policy.
          </p>
        </div>
        <div className="PrivacyPolicyModal__section">
          <p className="PrivacyPolicyModal__subtitle">12. HOW YOU CAN CONTACT US.</p>
          <p className="PrivacyPolicyModal__classic">
            If you have questions, comments, or complaints about this Privacy Policy or our practices under applicable
            data protection and consumer privacy laws, you may contact us at the contact information below. We will
            respond as soon as possible, but no later than the period required by applicable law.
          </p>
        </div>
        <div className="PrivacyPolicyModal__footer">
          <p className="PrivacyPolicyModal__classic" style={{ fontVariant: 'small-caps' }}>
            Sqwabl Inc.
          </p>
          <p className="PrivacyPolicyModal__classic">
            <span className="PrivacyPolicyModal__bold">Attention:</span> Laura Whitton
          </p>
          <p className="PrivacyPolicyModal__classic">
            <span className="PrivacyPolicyModal__bold">Email:</span> contact@sqwabl.com
          </p>
        </div>
      </div>
    </Dialog>
  );
};
