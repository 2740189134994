import * as classNames from 'classnames';
import * as React from 'react';
import './Drawer.scss';

type DrawerProps = React.HTMLAttributes<HTMLDivElement> & {
  backdrop?: boolean;
  isOpen: boolean;
  onClose?: () => void;
  testId?: string;
};

export const Drawer = ({ backdrop, className, isOpen, onClose, testId, ...rest }: DrawerProps) => {
  function getClassNames() {
    return classNames('Drawer', className);
  }

  function getOverlayClassNames() {
    return classNames('Drawer__overlay', {
      'Drawer__overlay--bg': backdrop,
    });
  }

  return (
    <>
      {isOpen && (
        <>
          <div className={getClassNames()} data-testid={testId} {...rest} />
          <div
            className={getOverlayClassNames()}
            data-testid={testId && `${testId}-overlay`}
            onClick={() => {
              if (onClose) {
                onClose();
              }
            }}
          ></div>
        </>
      )}
    </>
  );
};
