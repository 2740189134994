import * as classNames from 'classnames';
import * as React from 'react';
import { To } from 'react-router';
import { Link } from 'react-router-dom';
import './Button.scss';

export type ButtonColor =
  | 'default'
  | 'black'
  | 'white'
  | 'primary'
  | 'red'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'indigo'
  | 'violet'
  | 'fandango';

export type ButtonSize = 'sm' | 'md' | 'lg';

export type ButtonVariant = 'default' | 'raised' | 'outlined';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> & {
  color?: ButtonColor;
  fluid?: boolean;
  iconLeading?: string;
  iconTrailing?: string;
  navigateTo?: To;
  rounded?: boolean;
  size?: ButtonSize;
  small?: boolean;
  testId?: string;
  variant?: ButtonVariant;
};

export const Button = ({
  className,
  children,
  color = 'default',
  fluid,
  iconLeading,
  iconTrailing,
  navigateTo,
  rounded,
  size = 'md',
  testId,
  type = 'button',
  variant = 'default',
  ...rest
}: ButtonProps) => {
  function getClassNames() {
    return classNames(
      {
        'Button--fluid': fluid,
        'Button--iconLeading': iconLeading,
        'Button--iconTrailing': iconTrailing,
        'Button--rounded': rounded,
        [`Button--color--${color}`]: color,
        [`Button--size--${size}`]: size,
        [`Button--variant--${variant}`]: variant,
      },
      className
    );
  }

  if (navigateTo) {
    return (
      <Link className={getClassNames()} data-testid={testId} to={navigateTo} {...rest}>
        {iconLeading && <span className="iconLeading material-icons">{iconLeading}</span>}
        {children}
        {iconTrailing && <span className="iconTrailing material-icons">{iconTrailing}</span>}
      </Link>
    );
  }

  return (
    <button className={getClassNames()} data-testid={testId} type={type} {...rest}>
      {iconLeading && <span className="iconLeading material-icons">{iconLeading}</span>}
      {children}
      {iconTrailing && <span className="iconTrailing material-icons">{iconTrailing}</span>}
    </button>
  );
};
