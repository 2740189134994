import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from '~/src/ui';
import { UserModel } from '../../api';

type ResetPasswordFormProps = {
  resetPasswordKey: string | undefined;
};

type ResetPasswordFormData = {
  password1: string;
  password2: string;
};

const defaultFormData: ResetPasswordFormData = {
  password1: '',
  password2: '',
};

export const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const [errorMessage, setErrorMessage] = React.useState('');

  const { formState, handleSubmit, register } = useForm<ResetPasswordFormData>({
    defaultValues: defaultFormData,
  });
  const navigate = useNavigate();

  const onSubmit = handleSubmit((data) => {
    setErrorMessage('');
    UserModel.listAction('reset_password', 'post', {
      reset_password_key: props.resetPasswordKey,
      ...data,
    })
      .then(() => {
        navigate('/login');
      })
      .catch((err) => {
        if (!err.response.data) {
          return;
        }

        const { confirm_password, reset_password_key: reset_password_key_error, non_field_errors } = err.response.data;

        if (reset_password_key_error) {
          setErrorMessage(reset_password_key_error);
        } else if (confirm_password) {
          setErrorMessage(confirm_password);
        } else if (non_field_errors) {
          setErrorMessage(non_field_errors[0]);
        } else {
          setErrorMessage('An error occurred. Please try again.');
        }
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <Input fluid placeholder="Password" type="password" {...register('password1', { required: true })} />
      <Input
        className="mt-4"
        fluid
        placeholder="Password (Again)"
        type="password"
        {...register('password2', { required: true })}
      />
      <h6 className="hint">Passwords must be at least 8 characters, with at least one number and one letter.</h6>
      {errorMessage && <p className="error">{errorMessage}</p>}
      <Button className="mt-4" color="primary" disabled={!formState.isValid} fluid type="submit" variant="raised">
        Update Password
      </Button>
    </form>
  );
};
