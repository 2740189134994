import * as React from 'react';
import './WaveSvg.scss';

type WaveSvgProps = {
  flipped?: boolean;
};

export const WaveSvg = (props: WaveSvgProps) => {
  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setWidth(entry.contentRect.width);
        setHeight(entry.contentRect.width / 15);
      }
    });

    resizeObserver.observe(document.documentElement);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <div
      className="LandingPage__wave"
      id="waveComponent"
      style={props.flipped ? { transform: 'rotateZ(180deg)', margin: '-2px 0 0 0' } : { margin: '0 0 -2px 0' }}
    >
      <svg className="LandingPage__wave__svg" viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
        <clipPath id="wave">
          <path
            d={`
              M 0 ${height / 1.66} L ${width / 4.65} ${height / 1.66} C ${width / 1.71} ${height / 1.66} ${
              width / 1.66
            } 0 ${width / 1.31} 0 Q ${width / 1.16} 0 ${width} ${height / 1.66} L ${width} ${height} L 0 ${height} Z`}
          />
        </clipPath>
      </svg>
    </div>
  );
};
