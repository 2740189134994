import * as React from 'react';
import { Profile } from '~/src/features/auth';

// These routes can only be accessed by logged in users

export const protectedRoutes = [
  // {
  //   path: '/profile',
  //   element: <Profile />,
  // },
];
