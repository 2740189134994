import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Dialog, DialogProps, Input, useToast } from '~/src/ui';
import { UserModel } from '../../api';
import './ChangePasswordForm.scss';

type ChangePasswordFormProps = Omit<DialogProps, 'children'> & {};

type ChangePasswordFormData = {
  password: string;
  new_password1: string;
  new_password2: string;
};

const defaultFormData: ChangePasswordFormData = {
  password: '',
  new_password1: '',
  new_password2: '',
};

export const ChangePasswordForm = ({ onClose, ...rest }: ChangePasswordFormProps) => {
  const [errorMessage, setErrorMessage] = React.useState('');

  const { formState, handleSubmit, register, reset } = useForm<ChangePasswordFormData>({
    defaultValues: defaultFormData,
  });
  const { success } = useToast();

  const onSubmit = handleSubmit((data) => {
    UserModel.listAction('change_password', 'post', data)
      .then((res) => {
        localStorage.setItem('token', res.data.token);
        reset();
        success('Your password has been changed');
        if (onClose) {
          onClose();
        }
      })
      .catch((err) => {
        if (!err.response.data) {
          return;
        }

        const { password: password_error, confirm_password, non_field_errors } = err.response.data;

        if (password_error) {
          setErrorMessage(password_error);
        } else if (confirm_password) {
          setErrorMessage(confirm_password);
        } else if (non_field_errors) {
          setErrorMessage(non_field_errors[0]);
        } else {
          setErrorMessage('An error occurred. Please try again.');
        }
      });
  });

  return (
    <Dialog onClose={onClose} {...rest}>
      <form className="ChangePasswordForm" onSubmit={onSubmit}>
        <div className="ChangePasswordForm__field">
          <label>Current Password</label>
          <Input fluid type="password" {...register('password', { required: true })} />
        </div>
        <div className="ChangePasswordForm__field">
          <label>New Password</label>
          <Input fluid type="password" {...register('new_password1', { required: true })} />
        </div>
        <div className="ChangePasswordForm__field">
          <label>New Password (Again)</label>
          <Input fluid type="password" {...register('new_password2', { required: true })} />
        </div>
        {errorMessage && <p className="error">{errorMessage}</p>}
        <div className="ChangePasswordForm__actions">
          <Button
            type="button"
            variant="raised"
            onClick={() => {
              if (onClose) {
                onClose();
              }
            }}
          >
            Cancel
          </Button>
          <Button color="primary" disabled={!formState.isValid} type="submit" variant="raised">
            Save
          </Button>
        </div>
      </form>
    </Dialog>
  );
};
