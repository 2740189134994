import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '~/src/features/auth';
import { Button, Dialog, DialogProps, Input } from '~/src/ui';
import { UserModel } from '../../api';
import './RevokeTokenForm.scss';

type RevokeTokenFormProps = Omit<DialogProps, 'children'> & {};

type RevokeTokenFormData = {
  password: string;
};

const defaultFormData: RevokeTokenFormData = {
  password: '',
};

export const RevokeTokenForm = ({ onClose, ...rest }: RevokeTokenFormProps) => {
  const [errorMessage, setErrorMessage] = React.useState('');

  const { setUser } = useAuth();
  const { formState, handleSubmit, register } = useForm<RevokeTokenFormData>({
    defaultValues: defaultFormData,
  });
  const navigate = useNavigate();

  const onSubmit = handleSubmit((data) => {
    UserModel.listAction('revoke_token', 'post', data)
      .then((res) => {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        setUser(undefined);
        navigate('/login');
        if (onClose) {
          onClose();
        }
      })
      .catch((err) => {
        if (!err.response.data) {
          return;
        }

        const { password: password_error, confirm_password, non_field_errors } = err.response.data;

        if (password_error) {
          setErrorMessage(password_error);
        } else if (confirm_password) {
          setErrorMessage(confirm_password);
        } else if (non_field_errors) {
          setErrorMessage(non_field_errors[0]);
        } else {
          setErrorMessage('An error occurred. Please try again.');
        }
      });
  });

  return (
    <Dialog onClose={onClose} {...rest}>
      <form className="RevokeTokenForm" onSubmit={onSubmit}>
        <h3>Sign out everywhere</h3>
        <p>If you're sure you want to sign out everywhere, please enter your password:</p>
        <div className="RevokeTokenForm__field">
          <label>Password</label>
          <Input fluid type="password" {...register('password', { required: true })} />
        </div>
        {errorMessage && <p className="error">{errorMessage}</p>}
        <div className="RevokeTokenForm__actions">
          <Button
            type="button"
            variant="raised"
            onClick={() => {
              if (onClose) {
                onClose();
              }
            }}
          >
            Cancel
          </Button>
          <Button color="red" disabled={!formState.isValid} type="submit" variant="raised">
            Sign out everywhere
          </Button>
        </div>
      </form>
    </Dialog>
  );
};
