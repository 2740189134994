import * as React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useToast } from './ToastContext';
import './Toaster.scss';

export const Toaster = () => {
  const { toasts, clickToast } = useToast();

  if (toasts.length < 1) {
    return null;
  }

  return (
    <div className="Toaster" data-testid="toaster">
      {toasts.map((toast, index) => {
        let toastClass = `Toaster__toast Toaster__toast--${toast.type}`;
        return (
          <div
            className={toastClass}
            key={toast.id}
            onClick={() => {
              clickToast(index);
            }}
          >
            {toast.iconLeading}
            <div className="Toaster__toast__message">
              {toast.message.split('\n').map((line, index) => (
                <p className="Toaster__toast__message__line" key={index}>
                  {line}
                </p>
              ))}
            </div>
            <XMarkIcon className="Toaster__toast__close" />
          </div>
        );
      })}
    </div>
  );
};
