import * as React from 'react';
import { Error, Home, StyleGuide } from '~/src/features/core';
import { LandingPage } from '~src/features/landing';

// These routes can be accessed regardless of if you're logged in or out

export const commonRoutes = [
  {
    path: '/',
    element: <LandingPage />,
  },
  {
    path: '/style-guide',
    element: <StyleGuide />,
  },
  {
    path: '*',
    element: <Error />,
  },
];
