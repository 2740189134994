import * as React from 'react';

export type Toast = {
  id: number;
  message: string;
  type: string;
  iconLeading?: React.ReactNode;
};

type ToastContextType = {
  toasts: Toast[];
  clickToast: (index: number) => void;
  error: (message: string, iconLeading?: React.ReactNode) => void;
  info: (message: string, iconLeading?: React.ReactNode) => void;
  success: (message: string, iconLeading?: React.ReactNode) => void;
  warning: (message: string, iconLeading?: React.ReactNode) => void;
};

export const ToastContext = React.createContext<ToastContextType | undefined>(undefined);

type ToastProviderProps = {
  children: React.ReactNode;
};

export const ToastProvider = (props: ToastProviderProps) => {
  const [toasts, setToasts] = React.useState<Toast[]>([]);
  const toastsRef = React.useRef(toasts);
  toastsRef.current = toasts;

  const toast = (type: string) => {
    return (message: string, iconLeading?: React.ReactNode) => {
      const toastId = Date.now();
      setToasts((previousToasts: Toast[]) => [
        ...previousToasts.slice(-4),
        {
          type,
          message,
          id: toastId,
          iconLeading,
        },
      ]);
      setTimeout(() => {
        removeToast(toastId);
      }, 5000);
    };
  };

  const removeToast = (id: number) => {
    setToasts(
      toastsRef.current.filter((toast) => {
        return toast.id !== id;
      })
    );
  };

  const clickToast = (index: number) => {
    let newArr = [...toasts];
    newArr.splice(index, 1);
    setToasts(newArr);
  };

  return (
    <ToastContext.Provider
      value={{
        toasts,
        clickToast,
        error: toast('error'),
        info: toast('info'),
        success: toast('success'),
        warning: toast('warning'),
      }}
    >
      {props.children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = React.useContext(ToastContext);

  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
};
