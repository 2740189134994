import * as React from 'react';
import { Nav } from '~/src/components';
import { AppRoutes } from '~/src/routes';
import { Toaster } from '~/src/ui';

export const AppLayout = () => {
  return (
    <>
      <Toaster />
      {/* <Nav /> */}
      <AppRoutes />
    </>
  );
};
