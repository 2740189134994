import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Input } from '~/src/ui';
import { UserModel } from '../../api';

type ForgotPasswordFormData = {
  email: string;
};

const defaultFormData: ForgotPasswordFormData = {
  email: '',
};

export const ForgotPasswordForm = () => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const [submitted, setSubmitted] = React.useState<boolean>(false);

  const { formState, handleSubmit, register } = useForm<ForgotPasswordFormData>({
    defaultValues: defaultFormData,
  });

  const onSubmit = handleSubmit((data) => {
    setErrorMessage('');
    UserModel.listAction('send_password_reset_email', 'post', data)
      .then(() => {
        setSubmitted(true);
      })
      .catch((err) => {
        if (!err.response.data) {
          return;
        }

        const { email: email_error, non_field_errors } = err.response.data;

        if (email_error) {
          setErrorMessage(email_error);
        } else if (non_field_errors) {
          setErrorMessage(non_field_errors[0]);
        } else {
          setErrorMessage('An error occurred. Please try again.');
        }
      });
  });

  if (submitted) {
    return <p className="text-center my-8">Please check your email for a password reset link.</p>;
  }

  return (
    <form onSubmit={onSubmit}>
      <Input fluid placeholder="Email" type="email" {...register('email', { required: true })} />
      {errorMessage && <p className="error">{errorMessage}</p>}
      <Button className="mt-4" color="primary" disabled={!formState.isValid} fluid type="submit" variant="raised">
        Send Recovery Link
      </Button>
    </form>
  );
};
