import * as React from 'react';
import './AnimatedSubtitle.scss';

type AnimatedSubtitleProps = {
  text: string;
  delay?: number;
};

export const AnimatedSubtitle = (props: AnimatedSubtitleProps) => {
  const [words, setWords] = React.useState<string[]>([]);

  React.useEffect(() => {
    setWords(props.text.split(' '));
  }, [props.text]);

  function generateUniqueKey() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  return (
    <span className="AnimatedSubtitle__words">
      {words.map((word, index) => (
        <div
          key={generateUniqueKey()}
          className="AnimatedSubtitle__word"
          style={{ animationDelay: `${index * 60 + (props.delay || 0)}ms` }}
        >
          {word}
        </div>
      ))}
    </span>
  );
};

// Accomodates multi-line subtitles
export const subtitleComponents = [
  <AnimatedSubtitle text="Skip the endless back and forth." />,
  <AnimatedSubtitle text="A restaurant with something for everyone." />,
  <>
    <AnimatedSubtitle text="Just right movie night." />
    <AnimatedSubtitle text="(Who knew Terri would vote for the documentary?!)" delay={240} />
  </>,
  <AnimatedSubtitle text="Low drama book club." />,
  <AnimatedSubtitle text="A game we all love, on the table in record time." />,
  <AnimatedSubtitle text="Perfectly picked office lunch." />,
  <AnimatedSubtitle text="Oooh, a vacation spot we can all agree on." />,
  <AnimatedSubtitle text="What else do we need to decide? 😂" />,
];
