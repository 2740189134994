import * as React from 'react';
import * as classNames from 'classnames';
import './Carousel.scss';

type CarouselProps = {
  images: React.ReactNode[];
  duration: number;
  transitionDuration?: number;
  style?: React.CSSProperties;
  className?: string;
};

export const Carousel = ({transitionDuration = 500, ...props}: CarouselProps) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [transitioningOutIndex, setTransitioningOutIndex] = React.useState(-1);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => {
        setTransitioningOutIndex(prev);
        setTimeout(() => setTransitioningOutIndex(-1), transitionDuration);
        return (prev + 1) % props.images.length;
      });
    }, props.duration);
    return () => clearInterval(interval);
  }, [props.duration, props.images.length]);

  return (
    <div
      className={classNames('Carousel', props.className)}
      style={
        {
          '--duration': `${props.duration}ms`,
          '--transition-duration': `${transitionDuration}ms`,
          ...props.style,
        } as React.CSSProperties
      }
    >
      {props.images.map((image, index) => (
        <div
          key={index}
          className={classNames('Carousel__slide', {
            'Carousel__slide--current': index === currentSlide || index === transitioningOutIndex,
          })}
        >
          {image}
        </div>
      ))}
      <div className="Carousel__dots">
        {props.images.map((_, index) => (
          <div
            key={index}
            className={`Carousel__dot${index === currentSlide ? ' Carousel__dot--active' : ''}`}
            // onClick={() => setCurrentSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};
