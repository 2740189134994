import * as React from 'react';
import { ForgotPassword, Login, ResetPassword, SignUp, Verify } from '~/src/features/auth';

// These routes can only be accessed by users who are NOT logged in

export const publicRoutes = [
  // {
  //   path: '/forgot-password',
  //   element: <ForgotPassword />,
  // },
  // {
  //   path: '/login',
  //   element: <Login />,
  // },
  // {
  //   path: '/reset-password/:resetPasswordKey',
  //   element: <ResetPassword />,
  // },
  // {
  //   path: '/sign-up',
  //   element: <SignUp />,
  // },
  // {
  //   path: '/verify/:verificationKey',
  //   element: <Verify />,
  // },
];
