import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useAuth } from '~/src/features/auth';
import { Container } from '~/src/ui';

export const Home = () => {
  const { user } = useAuth();

  return (
    <>
      <Helmet>
        <title>Home | sqwabl</title>
      </Helmet>
      <Container>
        <div className="mt-12 text-center">
          <h1>Welcome to sqwabl!</h1>
          {user && <p>{user.email}</p>}
          <p>
            <Link to="/style-guide">Click here</Link> to view the style guide
          </p>
        </div>
      </Container>
    </>
  );
};
