import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from '~/src/features/auth';
import { ToastProvider } from '~/src/ui';

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = (props: AppProviderProps) => {
  return (
    <ToastProvider>
      <AuthProvider>
        <BrowserRouter>{props.children}</BrowserRouter>
      </AuthProvider>
    </ToastProvider>
  );
};
