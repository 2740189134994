export * as GooglePlay from './GooglePlay.svg';
export * as iOSAppStore from './iOSAppStore.svg';
export * as LogoFooter from './Logo_Footer.svg';
export * as LogoHeader from './Logo_Header.svg';
export * as Phones from './LandingPhones.png';
export * as Slide1 from './slide1.png';
export * as Slide2 from './slide2.png';
export * as Slide3 from './slide3.png';
export * as Slide4 from './slide4.png';
export * as Slide5 from './slide5.png';
export * as Slide6 from './slide6.png';
export * as Slide7 from './slide7.png';
export * as Slide8 from './slide8.png';
