import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Card, Container } from '~/src/ui';
import { LoginForm } from '../../components';

export const Login = () => {
  return (
    <>
      <Helmet>
        <title>Login | sqwabl</title>
      </Helmet>
      <Container>
        <div className="centerPage">
          <Card>
            <h1 className="text-center">Login</h1>
            <LoginForm />
            <Link className="block text-center mt-8" to="/forgot-password">
              Forgot Password?
            </Link>
            <p className="mt-8">
              Don't have an account? <Link to="/sign-up">Sign Up</Link>
            </p>
          </Card>
        </div>
      </Container>
    </>
  );
};
