import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { useCountdown } from '~/src/hooks';
import { Card, Container } from '~/src/ui';
import { UserModel } from '../../api';

export const Verify = () => {
  const [error, setError] = React.useState('');

  const { verificationKey } = useParams();
  const { remaining } = useCountdown({
    seconds: 5,
    onComplete: () => {
      window.location.replace('/login');
    },
  });

  React.useEffect(() => {
    setError('');
    UserModel.listAction('verify_email', 'post', {
      verification_key: verificationKey,
    })
      .then(() => {})
      .catch((err) => {
        const { verification_key, already_verified, non_field_errors } = err.response.data;

        if (verification_key) {
          setError(verification_key);
        } else if (already_verified) {
          setError(already_verified);
        } else if (non_field_errors) {
          setError(non_field_errors[0]);
        } else {
          setError('An error occurred. Please try again.');
        }
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Verify Email | sqwabl</title>
      </Helmet>
      <Container>
        <div className="centerPage">
          <Card>
            <h1 className="text-center">Verify Email</h1>
            {error ? (
              <p className="error">{error}</p>
            ) : (
              <p>
                Your account email has been verified. <Link to="/login">Click here</Link> to login.
              </p>
            )}
            <p>You will be redirected to the login page in {remaining} seconds.</p>
          </Card>
        </div>
      </Container>
    </>
  );
};
