import * as classNames from 'classnames';
import * as React from 'react';
import './Badge.scss';

type BadgeProps = React.HTMLAttributes<HTMLDivElement> & {
  color?: 'black' | 'red' | 'orange' | 'yellow' | 'green' | 'blue' | 'indigo' | 'violet' | 'endeavour';
  testId?: string;
};

export const Badge = ({ className, color, testId, ...rest }: BadgeProps) => {
  function getClassNames() {
    return classNames(
      'Badge',
      {
        [`Badge--${color}`]: color,
      },
      className
    );
  }

  return <div className={getClassNames()} data-testid={testId} {...rest} />;
};
