import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Card, Container } from '~/src/ui';
import { SignUpForm } from '../../components';

export const SignUp = () => {
  const [submitted, setSubmitted] = React.useState(false);

  if (submitted) {
    return (
      <>
        <Helmet>
          <title>Almost there! | sqwabl</title>
        </Helmet>
        <Container testId="submitted-container">
          <div className="centerPage">
            <h1 className="text-center">Almost there!</h1>
            <p className="text-center mt-8">Please check your email to verify your account.</p>
          </div>
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Sign Up | sqwabl</title>
      </Helmet>
      <Container>
        <div className="centerPage">
          <Card>
            <h1 className="text-center">Sign Up</h1>
            <SignUpForm
              onSuccess={() => {
                setSubmitted(true);
              }}
            />
            <p className="mt-8">
              Already have an account? <Link to="/login">Log In</Link>
            </p>
          </Card>
        </div>
      </Container>
    </>
  );
};
