import { BaseModel } from '~/src/http';

type Contact = {
  name: string;
  email: string;
  message: string;
};

type Subscription = {
  email: string;
}

export const ContactModel = new BaseModel<Contact>('/api/contact/');
export const SubscriptionModel = new BaseModel<Subscription>('/api/subscribe/');
